import React from "react";
import { useHistory } from "react-router-dom";
import SocialLocationPostUpdateForm from "./SocialLocationPostUpdateForm";
import { getImagesForCompany } from "../../api/companyApi";
import { useDispatch, useSelector } from "react-redux";
import SocialLocationPostEventAndOfferForm from "./SocialLocationPostEventAndOfferForm";
import SocialLocationPostOfferForm from "./SocialLocationPostOfferForm";
import { setLoading } from "../../commons/components/application/miscActions";
import withEventTracking, { trackers } from "../../config/withEventTracking";
import { SocialLocationPostCallToActionType, SocialLocationPostTopicType } from "./SocialLocationPostCallToActionType";
import translate from "../../config/translations/translate";
import { createSocialLocationPost } from "../../api/socialLocationPostsApi";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { LocationSelectAndSubmitWrapper } from "./LocationSelectAndSubmitWrapper";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UxRadioButton,
} from "botbit-ui-components";
import { updateIcon, offerIcon, eventIcon } from "./icons";

const MySwal = withReactContent(Swal);

const PostModal = ({ isOpen, toggle, title, body, footer }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <div style={{ maxHeight: "55vh" }}>{body}</div>
      </ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export function SocialLocationPostContainer({
  trackEvent,
  t,
  modal,
  socialLocationId,
}) {
  // const path = "/";
  const history = useHistory();
  // const [postData, setPostData] = React.useState();
  const dispatch = useDispatch();
  const [images, setImages] = React.useState();
  const [selectedType, setSelectedType] = React.useState("update");
  const formRef = React.useRef();
  const [selectedLocations, setSelectedLocations] = React.useState([]);

  const company = useSelector((state) => state.application.selectedCompany);
  const emmit = (action) =>
    trackEvent(action.toLowerCase(), {}, false, [
      trackers.BACKEND,
      trackers.MIXPANEL,
    ]);

  React.useEffect(() => {
    if (!images) {
      dispatch(setLoading(true));
      getImagesForCompany(company.id, 1024).then((res) => {
        setImages(res.data.data);
        dispatch(setLoading(false));
      });
    }
    emmit("localpost_viewed");
  }, []);

  React.useEffect(() => {
    if (modal && modal.isOpen) {
      setSelectedType("update");
    }
  }, [modal !== undefined && modal.isOpen]);

  const submitHandlerBuilder = (eventName) => (values) => {
    // setPostData(values);
    // history.push(`${path}/selectlocations`);
    emmit(eventName);
    createPost(values);
  };

  const renderForm = () => {
    const typeButtonGroupStyle = modal===undefined ? {
      justifyContent: "center",
      display: "flex",
    } : {};
    return (
      <div className="row">
        <div className="col-12" style={typeButtonGroupStyle}>
        <UxRadioButton
          options={[
            {
              icon: (
                updateIcon
              ),
              title: t("update"),
              value: "update",
            },
            {
              icon: (
                eventIcon
              ),
              title: t("event"),
              value: "event",
            },
            {
              icon: (
                offerIcon
              ),
              title: t("offer"),
              value: "offer",
            },
          ]}
          onChange={(v) => setSelectedType(v)}
        />
        </div>
        <div className="col-12">
          {postFormSwitch()}
        </div>
      </div>
    );
  };

  const postFormSwitch = () => {
    switch (selectedType) {
      case "update":
        return (
          <SocialLocationPostUpdateForm
            socialLocationPost={{
              callToAction: {
                actionType:SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED
              }
            }}
            images={images}
            onSubmit={submitHandlerBuilder("localpost_selectLocations_viewed")}
            formRef={formRef}
          />
        );

      case "event":
        return (
          <SocialLocationPostEventAndOfferForm
            socialLocationPost={{
              topicType: SocialLocationPostTopicType.EVENT,
              callToAction: {
                actionType:SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED
              }
            }}
            images={images}
            onSubmit={submitHandlerBuilder("localpost_selectLocations_viewed")}
            formRef={formRef}
          />
        );
      case "offer":
        return (
          <SocialLocationPostOfferForm
            socialLocationPost={{
              topicType: SocialLocationPostTopicType.OFFER,
              callToAction: {
                actionType:SocialLocationPostCallToActionType.ACTION_TYPE_UNSPECIFIED
              }
            }}
            images={images}
            onSubmit={submitHandlerBuilder("localpost_selectLocations_viewed")}
            formRef={formRef}
          />
        );
      default:
        return null;
    }
  };

  const resetAndToggleModal = ()=>{
    formRef.current = undefined;
    setSelectedType(null);
    setSelectedLocations(null);
    if(modal && modal.toggle) modal.toggle();
  }

  const createPost = (values) => {
    const postServiceBody = {
      ...values,
      socialAccountWithSocialLocations: selectedLocations,
    };
    dispatch(setLoading(true));
    createSocialLocationPost(postServiceBody).then((res) => {
      dispatch(setLoading(false));
      const allOk = res.data.data.every((x) => x.success === true);
      if (res.status === 200) {
        if (allOk) {
          MySwal.fire({
            type: "success",
            text: t("swalText"),
            confirmButtonText: t("ok"),
            showCloseButton: true,
          }).then(() => {
            //history.push("/localseo/posts");
            resetAndToggleModal()
          });
        } else {
          // setCreationResult(res.data.data);
          setSelectedLocations(
            res.data.data
              .filter((x) => x.success === false)
              .map((x) => x.locationId)
          );
          MySwal.fire({
            type: "error",
            text: t("swalErrorText"),
            confirmButtonText: t("ok"),
            showCloseButton: true,
          });
        }
      } else {
        history.push("/localseo/posts");
      }
    });
  };

  if (modal) {
    return (
      <PostModal
        isOpen={modal.isOpen}
        toggle={() => {
          resetAndToggleModal()
        }}
        title={<>
          <h3>{t("modalTitle")}</h3>
          <h6>{t("modalSubtitle")}</h6>
        </>}
        body={renderForm()}
        footer={
          <LocationSelectAndSubmitWrapper
            setSelectedLocations={setSelectedLocations}
            formRef={formRef}
            t={t}
            socialLocationId={socialLocationId}
            selectedLocations={selectedLocations}
          />
        }
      />
    );
  }

  return (
    <div className="m-auto p-5 bg-white gbp-container" style={{maxWidth:"900px"}}>
      <>
        {renderForm()}
        <hr />
        {selectedType && (
          <LocationSelectAndSubmitWrapper
            setSelectedLocations={setSelectedLocations}
            formRef={formRef}
            selectedLocations={selectedLocations}
            t={t}
          />
        )}
      </>
    </div>
  );
}

SocialLocationPostContainer.translations = {
  submit: {
    en: "Publish",
    es: "Publicar",
  },
  update:{
    en: "Update",
    es: "Novedad",
  },
  event:{
    en: "Event",
    es: "Evento",
  },
  offer:{
    en: "Offer",
    es: "Oferta",
  },
  modalTitle:{
    en: "Create a post",
    es: "Crea un post",
  },
  swalText: {
    en: "Post created",
    es: "Publicación creada",
  },
  swalErrorText: {
    en: "There was an error creating the post",
    es: "Hubo un error creando la publicación",
  },
  ok: {
    en: "Ok",
    es: "Aceptar",
  },
  selectLocations: {
    en: "Select locations",
    es: "Selecciona los locales",
  },
  modalSubtitle:{
    es: "¡Realiza un nuevo post en tus perfiles de Google My Business!",
    en: "Create a new post in your Google My Business profiles!"
  }
};

export default withEventTracking(translate(SocialLocationPostContainer));
