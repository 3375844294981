import React, { useEffect } from 'react';
import { translate } from 'react-i18next';
import './ConfigurationsContainer.scss';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { envConfig } from '../../config/config';
import withReactContent from 'sweetalert2-react-content';
import swal from 'sweetalert2';
import { useCompanyAvailableStores } from "../../commons/utils/useCompanyAvalaibleStores";
import { firePageView } from "../../commons/components/utils/trackers";

const MySwal = withReactContent(swal);

const ConfigurationsContainer = ({ t, history }) => {
	const selectedCompany = useSelector(state => state.application.selectedCompany)
	const companyStores = useCompanyAvailableStores();
	const token = useSelector(state => state.login.token)
	const admin = useSelector(state => state.login.admin)

	useEffect(() => {
		firePageView();
	}, []);

	const userHasAuthority = (permit) => {
		return admin.operations.includes(permit);
	};

	const getFirstStoreId = () => {
		return companyStores[0].id;
	};

	const isThereAtLeastOneStoreToSelect = () =>
		companyStores && companyStores.length > 0;

	const isCompanyAdminOrMotu = (admin.objectAccess === "ALL" ||
		(admin.objectAccess === "COMPANY" && admin.companies.some(company => company.id === selectedCompany.id)))

	const configurations = [
		{
			id: 'YOUR_BOTBIT',
			children: [
				{
					icon: 'mi-empresa.png',
					id: 'COMPANIES',
					url: '/companies',
					visible: userHasAuthority('COMPANIES__VIEW'),
				},
				{
					icon: 'mis-tiendas.png',
					id: 'STORES',
					visible: userHasAuthority('STORES__EDIT'),
					permissions: {
						canEnter: isThereAtLeastOneStoreToSelect(),
						errMsg: t('configurations.thereIsNotStoreToSelect'),
					},
					url: `${envConfig.frontendPhpUrl
						}/storeConfigWizard.php?storeId=${getFirstStoreId()}`,
				},
				{
					icon: 'equipo-de-trabajo.png',
					id: 'WORK_TEAM',
					visible: userHasAuthority('ADMINISTRATORS__ACTIVATE') && userHasAuthority('ADMINISTRATORS__DELETE'),
					url: '/settings/administrators'
				},
				{
					icon: 'equipo-de-trabajo.png',
					id: 'ADMIN_INVITE',
					visible: true,
					url: `/settings/invite`,
				},
				{
					icon: 'perfil.png',
					id: 'MY_PROFILE',
					url: `/settings/administrators/${admin.id}`,
				},
			],
		},
		{
			id: 'ONLINE_STORE',
			visible: false,
			children: [
				{
					icon: 'tienda.png',
					id: 'STORE',
					url: '/orders/settingsShop',
					visible: userHasAuthority('ORDERS__VIEW'),
				},
				{
					icon: 'canales.png',
					id: 'CHANNELS',
					url: '/orders/settingsChannels',
					visible: userHasAuthority('ORDERS_CHANNELS__CONFIG'),
				},
				{
					icon: 'menu-de-chat.png',
					id: 'CHAT_MENU',
					url: '/orders/settingsBuilder',
					visible: userHasAuthority('ORDERS__CONFIG'),
				},
			],
		},
		{
			id: 'FEEDBACK_CENTER',
			children: [
				{
					icon: 'tiendas-conectadas.png',
					id: 'CONNECTED_STORES',
					visible: userHasAuthority('REVIEWS__VIEW'),
					url: '/feedback/connectedstores',
				},
				{
					icon: 'API.png',
					id: 'WIDGET',
					visible: userHasAuthority('REVIEWS_WIDGET__VIEW'),
					url: 'feedback/widget'
				}
			],
		},
		{
			id: 'REVIEW_SENTIMENT',
			children: [
				{
					icon: 'keywords.png',
					id: 'REVIEW_SENTIMENT_KEYWORDS',
					visible: envConfig.companiesWithSentiments.includes(selectedCompany.id) && isCompanyAdminOrMotu,
					url: '/settings/sentiments/keywords',
				}
			],
		},
		{
			id: 'AUTOMATION',
			children: [
				{
					icon: 'rtas-automaticas.png',
					id: 'AUTO_REPLIES',
					visible: userHasAuthority('REVIEWS__VIEW'),
					url: '/feedback/autoreplyrules',
				},
				{
					icon: 'plantilla.png',
					id: 'REPLY_TEMPLATES',
					visible: userHasAuthority('REVIEWS__VIEW'),
					url: '/feedback/replytemplates',
				},
				{
					icon: 'flows.png',
					id: 'FLOWS',
					visible: userHasAuthority('REVIEWS__VIEW'),
					url: '/flows',
				}
			],
		},
		{
			id: 'MY_APPS',
			children: [
				{
					icon: 'reseñas.png',
					id: 'REVIEWS',
					//Social Accounts - Integración con Redes Sociales para obtener
					visible: userHasAuthority('STORES__EDIT'),
					url: '/socialaccounts',
				},
				{
					icon: 'API.png',
					id: 'API',
					visible: userHasAuthority('STORES__EDIT'),
					url: '/api',
				},
				{
					icon: 'INTEGRATIONS.jpg',
					id: 'INTEGRATIONS',
					visible: userHasAuthority('STORES__EDIT'),
					url: '/integrations'
				}
			],
		},
		{
			id: 'OTHER',
			children: [
				{
					icon: 'promocodes.png',
					id: 'PROMOCODES',
					visible: userHasAuthority('PROMO_CODES__EDIT'),
					url: `${envConfig.frontendPhpUrl}/preloadQr.php`,
				},
				{
					icon: 'issues.png',
					id: 'ISSUES',
					visible: true,
					url: `/settings/issues`,
				},
			],
		},
		{
			id: 'BOTBIT',
			children: [
				{
					icon: 'salesforce.png',
					id: 'SALESFORCE',
					visible: false,
					url: '/salesforce',
				},
			],
		},
	];

	const handleClick = (clickedItem, event, permissions) => {
		event.preventDefault();
		if (permissions && !permissions.canEnter) {
			MySwal.fire({
				type: 'error',
				title: 'Error',
				text: permissions.errMsg,
			});
		} else {
			// url externa php
			if (clickedItem.url.startsWith('http')) {
				let newUrl = clickedItem.url;
				if (newUrl.indexOf('?') > 0)
					newUrl = newUrl + `&sso=${token}&companyId=${selectedCompany.id}`;
				else newUrl = newUrl + `?sso=${token}&companyId=${selectedCompany.id}`;

				window.location.href = newUrl;
			}
			// es una URL node.js
			else {
				history.push(clickedItem.url);
			}
		}
	};

	const hasVisibleChildren = (obj) => {
		let visibleChildren = obj.children.filter(
			(c) => c.visible === undefined || c.visible
		);
		return visibleChildren && visibleChildren.length > 0;
	};

	return (
		<div className="configurations-container col-lg-8 col-sm-12 pb-5">
			<h2>{t('configurations.title')}</h2>
			<h3>{t('configurations.subtitle')}</h3>
			{configurations.map(
				(c) =>
					hasVisibleChildren(c) &&
					(c.visible === undefined || c.visible) && (
						<div key={c.id} data-intercom-target={c.id} className="parent my-4">
							<h3>{t(`configurations.${c.id}`)}</h3>
							<div className="childs">
								{c.children.map(
									(child) => {
										return (child.visible === undefined || child.visible) && (
											<p
												onClick={(e) =>
													handleClick(child, e, child.permissions)
												}
												key={child.id}
												data-intercom-target={child.id}
												data-botbit-security={"configurations-" + child.id}
											>
												{child.icon && (
													<img
														alt=""
														src={`${envConfig.staticImgUrl}${child.icon}`}
													/>
												)}
												{t(`configurations.${child.id}`)}
											</p>
										)
									}

								)}
							</div>
						</div>
					)
			)}
		</div>
	);
};

export default withRouter(translate('common')(ConfigurationsContainer));
