import React from 'react';
import { connect } from 'react-redux';
import ClientsTable from './ClientsTable';
import { getSegmentForStores, getSegmentUsers } from '../../api/segmentApi';
import { createUser } from '../../api/userApi';
import { exportAdminEvents } from '../../api/adminEventApi.js';
import { setLoading } from '../../commons/components/application/miscActions';
import ImportUsersModal from './ImportUsersButton';
import { translate } from 'react-i18next';
import { exportUsers } from '../../api/segmentApi.js';
import 'react-intl-tel-input/dist/main.css';
import './clients.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
	withRouter
} from 'react-router-dom'
import withStoreSelector from './withStoreSelector';
import { firePageView } from "../../commons/components/utils/trackers";

const MySwal = withReactContent(Swal);
const allClientsSegment = (stores, availableStores) => {
	let segmentStores;
	if (stores) {
		if (!Array.isArray(stores)) {
			if (stores.id !== -1)
				segmentStores = [{ id: stores.id }];
		} else
			segmentStores = stores.map((s) => ({ id: s.id }));
	}
	if (!segmentStores) {
		segmentStores = availableStores.filter(s => s.id > 0).map((s) => ({ id: s.id }));
	}
	return {
		stores: segmentStores,
		value: -1,
		label: 'Todos los clientes',
		id: undefined,
		name: 'Todos los clientes',
		filters: [],
		useCompanyUsers: false,
		useCompanyEvents: false
	}
}


const sortfieldsTraduction = {
	name: "nombre",
	lastName: "apellido",
}

const getOrderBy = (sortField) => {
	if (!sortField)
		return undefined;
	let orderBy = sortfieldsTraduction[sortField];
	if (!orderBy)
		orderBy = sortField;
	return orderBy;
}


class ClientsContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = { users: [], segments: [], pageNumber: 1, showMetrics: false };
		const params = new URLSearchParams(this.props.queryParams);
		this.segmentId = params.get('segment');
	}

	componentDidMount() {
		if (!this.props.selectedStore || this.props.selectedStore.id === -1) {
			//this.props.storeChange(this.props.availableStores[0]);
			//return;
		}
		this.updateClients();
		firePageView();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedStore !== this.props.selectedStore) {
			this.updateClients();
		}
	}

	updateClients = () => {
		this.selectedStoreChange((this.props.selectedStore && this.props.selectedStore.id !== -1) ? this.props.selectedStore.id : undefined)
	}

	userToRow = (user) => {
		var registration;
		if (!this.props.selectedStore) {
			registration = {};
			user.storeRegistrations.forEach(
				(sr) => {
					if (!registration.registrationDate || registration.registrationDate > sr.registrationDate)
						registration.registrationDate = sr.registrationDate;
					if (!registration.lastVisitationDate || registration.lastVisitationDate < sr.lastVisitationDate)
						registration.lastVisitationDate = sr.lastVisitationDate;
				}
			);
		}
		else {
			if (user.storeRegistrations) {
				registration = user.storeRegistrations.find((sr) => {
					return (sr.storeId === this.props.selectedStore.id);
				});
			} else {
				registration = {}
			}
		}

		if (!registration) return { ...user };
		const { registeredThrough, ignored, ignoredBy, lastVisitationDate, allowAutomaticIgnore, registrationDate } = registration;
		return {
			...user,
			registeredThrough,
			allowAutomaticIgnore,
			ignored,
			ignoredBy,
			lastVisitationDate,
			registrationDate
		}
	}

	usersToRows = (users) => {
		return users.map(this.userToRow);
	}

	selectedStoreChange = (storeId) => {
		let stores;
		let exportSegment;
		let strict;
		if (storeId !== undefined) {
			stores = [{ id: storeId }];
			exportSegment = allClientsSegment(stores, this.props.availableStores);
			strict = true;
		}
		else {
			stores = this.props.availableStores;
			strict = false;
		}

		this.props.setLoading(true);
		this.setState({ selectedSegment: undefined, segments: undefined, users: [], exportSegment: exportSegment });

		getSegmentForStores(stores.filter(s => s.id > 0).map(s => s.id), this.props.selectedCompany.id, strict)
			.then((res) => {
				var allClients = allClientsSegment(stores, this.props.availableStores);
				var segments = res.data.data;
				segments.unshift(allClients);
				this.setState({ segments: segments, selectedSegment: allClients });
			}).finally(() => {
				this.props.setLoading(false);
			});

	}

	ignoredAndBlacklisted = (selectedSegment, selectedStore) => {
		return true;
		/*if ( !selectedStore)
			return false;
		else
			return (
				(selectedSegment === undefined || selectedSegment.id === undefined) &&
				selectedStore.id !== -1
			);*/
	};

	selectedSegmentChange = (segment, pageNumber, pageSize, sortField, sortOrder) => {
		this.props.setLoading(true);
		var findIgnoredAndBlacklistedUsers = this.ignoredAndBlacklisted(segment, this.props.selectedStore);
		let exportSegment = segment.id ? segment : this.state.exportSegment;
		this.setState({ users: [], ignoredAndBlacklisted: findIgnoredAndBlacklistedUsers, pageNumber: 1, exportSegment: exportSegment });
		getSegmentUsers(segment, pageNumber, pageSize, findIgnoredAndBlacklistedUsers, sortField, sortOrder)
			.then((data) => {
				var selectedSegment = { ...data.segment, value: data.segment.id, label: data.segment.name }
				this.setState({ showMetrics: false, selectedSegment: selectedSegment, users: this.usersToRows(data.users) });
			})
			.finally(() => {
				this.props.setLoading(false);
			});
	}

	getUsersPage = ({ sortField, sortOrder }) => {
		if (this.state.selectedSegment !== undefined) {
			this.props.setLoading(true);
			var pageNumber = this.state.pageNumber + 1;
			getSegmentUsers(this.state.selectedSegment, pageNumber, undefined, this.state.ignoredAndBlacklisted, sortField, sortOrder)
				.then((res) => {
					if (res.users && res.users.length > 0) {

						this.setState(
							{
								users: [
									...this.state.users,
									...this.usersToRows(res.users)
								],
								pageNumber
							});
					}
					else {
						MySwal.fire({
							title: this.props.t("panels.clients.noResults"),
							text: this.props.t("panels.clients.noResultsText"),
							type: 'info',
							showCancelButton: false,
							confirmButtonText: this.props.t("confirmButton"),
							confirmButtonColor: '#1ab394'
						})
					}
				})
				.finally(() => { this.props.setLoading(false); });
		}
	}

	updateRow = (user) => {
		this.setState(
			{
				users: this.state.users.map(u => {
					if (u.id === user.id) {
						if (user.email)
							return this.userToRow(user);
						else
							return null;
					}
					else
						return u;
				}).filter(function (el) {
					return el != null;
				})
			}
		)
	}


	sortChange = (sortField, sortOrder) => {
		this.props.setLoading(true);
		this.setState({ pageNumber: 1 });
		getSegmentUsers(this.state.selectedSegment, undefined, undefined, this.state.ignoredAndBlacklisted, getOrderBy(sortField), sortOrder)
			.then((res) => {
				var users = res.users;
				if (users && users.length > 0)
					this.setState({ showMetrics: false, users: this.usersToRows(users) });
			})
			.finally(() => {
				this.props.setLoading(false);
			});
	}

	filterChange = (newSegment, sortField, sortOrder) => {
		this.props.setLoading(true);
		this.setState({ selectedSegment: newSegment, pageNumber: 1 });
		getSegmentUsers(newSegment, undefined, undefined, this.state.ignoredAndBlacklisted, getOrderBy(sortField), sortOrder)
			.then((res) => {
				var users = res.users;
				if (users && users.length > 0)
					this.setState({ showMetrics: false, users: this.usersToRows(users) });
			})
			.finally(() => {
				this.props.setLoading(false);
			});
	}


	getSegment = (filters) => {
		const stores = this.props.selectedStore ? this.props.selectedStore : this.props.availableStores;
		let newSegmentFilters = [];
		if (filters.name) {
			var nameFilter = {
				"filterAttribute": {
					"id": 14
				},
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.nombre"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "2"
				},
				"operatorValues": [
					filters.name
				],
				"connector": "AND"
			}
			newSegmentFilters.push(nameFilter);
		}
		if (filters.lastName) {
			var lastnameFilter = {
				"filterAttribute": {
					"id": 15
				},
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.apellido"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "2"
				},
				"operatorValues": [
					filters.lastName
				],
				"connector": "AND"
			}
			newSegmentFilters.push(lastnameFilter);
		}
		if (filters.phone) {
			var phoneFilter = {
				"filterAttribute": {
					"id": 27
				},
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.phone"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "2"
				},
				"operatorValues": [
					filters.phone
				],
				"connector": "AND"
			}
			newSegmentFilters.push(phoneFilter);
		}
		if (filters.gender.length > 0) {
			var genderFilter = {
				"filterAttribute": {
					"id": 3
				},
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.gender"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "5"
				},
				"operatorValues": [
					filters.gender[0]
				],
				"connector": "AND"
			}
			newSegmentFilters.push(genderFilter);

		}
		if (filters.email) {
			var emailFilter = {
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.email"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "17"
				},
				"operatorValues": [
					filters.email
				],
				"connector": "AND"
			}
			newSegmentFilters.push(emailFilter);
		}
		if (filters.identityNumber) {
			var identityNumberFilter = {
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.identityNumber"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "17"
				},
				"operatorValues": [
					filters.identityNumber
				],
				"connector": "AND"
			}
			newSegmentFilters.push(identityNumberFilter);
		}
		if (filters.country.length > 0) {
			var countryFilter = {
				"function": {
					"id": "5"
				},
				"functionValues": [
					"u.country"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "8"
				},
				"operatorValues":
					filters.country
				,
				"connector": "AND"
			}
			newSegmentFilters.push(countryFilter);
		}
		if (filters.status && filters.status.length > 0) {
			var statusFilter = {
				"filterAttribute": {
					"id": "8"
				},
				"function": {
					"id": "5"
				},
				"functionValues": [
					"cs.statusTypeId"
				],
				"operatorType": "STRING",
				"operator": {
					"id": "8"
				},
				"operatorValues": filters.status,
				"connector": "AND"
			}
			newSegmentFilters.push(statusFilter);
		}
		if (filters.iddle && filters.iddle[0] !== undefined) {
			const dateFrom = new Date(filters.iddle[0].from);
			const dateTo = new Date(filters.iddle[0].to);
			const diff = Math.floor((dateTo.getTime() - dateFrom.getTime()) / 1000);
			const iddleFilter = [
				{
					"connector": "AND",
					"function": {
						"id": "8"
					},
					"functionValues": [
						"33",
						diff
					],
					"operator": {
						"id": "5"
					},
					"operatorType": "INTEGER",
					"operatorValues": [
						"0"
					]
				},
				{
					"connector": "AND",
					"function": {
						"id": "8"
					},
					"functionValues": [
						"34",
						diff
					],
					"operator": {
						"id": "5"
					},
					"operatorType": "INTEGER",
					"operatorValues": [
						"0"
					]
				}
			];
			newSegmentFilters.push(...iddleFilter);
		}

		let newSegment;
		if (filters.segments[0])
			newSegment = { id: filters.segments[0], name: this.getSegmentName(filters.segments[0]), extraFilters: newSegmentFilters };
		else {
			newSegment = allClientsSegment(stores, this.props.availableStores);
			newSegment.filters = newSegmentFilters;
		}
		return newSegment;
	}

	getSegmentName = (id) => {
		if (!this.state.segments)
			return undefined;
		const segment = this.state.segments.find(s => s.id === id);
		return segment ? segment.name : undefined;
	}

	getData = (page, order = {}, filters) => {
		var segment = this.getSegment(filters);
		this.setState({ segment: segment });
		return new Promise(
			resolve => {
				getSegmentUsers(segment, page + 1, 10, true, order.name, order.order)
					.then(
						response => {
							let result = {
								data: { data: {} }
							}
							result.data.data.items =
								this.usersToRows(response.users);
							resolve(result);
						}
					)
			}
		)
	}


	createUser = (user, store) => {
		createUser(user, store)
			.then(
				response => {
					let storeId = this.props.selectedStore ? `?storeId=${this.props.selectedStore.id}` : ''
					this.props.history.push(`/crm/user/${response.data.data.id}${storeId}`);
				}
			);
	}

	exportAdminEvents = () => {
		if (!this.props.selectedStore) return;
		this.props.setLoading(true);
		exportAdminEvents(undefined, { storeId: this.props.selectedStore.id, companyId: this.props.selectedCompany.id })
			.then(
				response => {
					let fileName = "AdminActivity.csv";
					let fileBlob = response.data;
					try {
						var blob = new Blob([fileBlob], { type: "text/csv" });
						//downloading the file depends on the browser
						//IE handles it differently than chrome/webkit
						if (window.navigator && window.navigator.msSaveOrOpenBlob) {
							window.navigator.msSaveOrOpenBlob(blob, fileName);
						} else {
							var elem = window.document.createElement('a');
							elem.href = window.URL.createObjectURL(blob);
							elem.download = fileName;
							document.body.appendChild(elem);
							elem.click();
							document.body.removeChild(elem);
						}
					} catch (exc) {
						console.log("Save Blob method failed with the following exception.");
						console.log(exc);
					}
					this.props.setLoading(false);
				}
			)
	}

	exportUsers = (order, filters) => {
		this.props.setLoading(true);
		let segment = this.state.segment;
		return new Promise((resolve, reject) => {
			exportUsers(segment)
				.then(
					(response) => {
						resolve(response.data);
					}
				)
				.finally(
					() => {
						this.props.setLoading(false);
					}
				);
		})
	}

	render() {
		const menuOpptions = [
			(<ImportUsersModal store={this.props.selectedStore} admin={this.props.admin} />)
		];
		return (
			<div className='p-0'>
				{this.state.segments && <ClientsTable
					menuOptions={menuOpptions}
					getData={this.getData}
					clients={this.state.users}
					getUsersPage={this.getUsersPage}
					totalSize={this.state.targetedUsers}
					updateRow={this.updateRow}
					actionsOptions={{ showIgnore: this.ignoredAndBlacklisted(this.state.selectedSegment, this.props.selectedStore) }}
					storeView={this.props.selectedStore && this.props.selectedStore.id !== -1}
					segments={this.state.segments}
					selectedSegment={this.segmentId}
					availableStores={this.props.availableStores}
					createUser={this.createUser}
					selectedStore={this.props.selectedStore || undefined}
					selectedStoreIsSuscription={this.props.selectedStore ? this.props.selectedStore.storeCategory.isSuscription : undefined}
					admin={this.props.admin}
					exportUsers={this.exportUsers}
					exportName={(this.props.selectedStore ? this.props.selectedStore.name + '-' : '') + (this.state.segment ? this.state.segment.name : '')}
					exportAdminEvents={this.exportAdminEvents}
					token={this.props.token}
				/>}
				<br />
				<br />
			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		selectedCompany: state.application.selectedCompany,
		admin: state.login.admin,
		token: state.login.token
	}
};


export default withStoreSelector(connect(mapStateToProps, { setLoading })(translate('common')(withRouter(ClientsContainer))));



