import React, { useEffect } from "react";
import translate from "../../../config/translations/translate";
import { MDBBtn, MDBCard } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import Score from "../../feedback/Score";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "botbit-ui-components";
import { useSelector } from "react-redux";
import { getPlaceImprovementsSuggestions } from "../../../api/googleBusinessProfileApi";

const LocationCard = ({ source, t, duplicated }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <MDBCard className="p-3" style={{ opacity: 1 }}>
      <h3 className="text-dark">
        {duplicated && source.possibleDuplicate && (
          <>
            <FontAwesomeIcon
              onClick={() => setModalOpen(true)}
              icon={faExclamationCircle}
              className="text-danger float-right cursor-pointer ml-1"
            />
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
              <ModalHeader toggle={() => setModalOpen(false)}>{t("duplicated")}</ModalHeader>
              <ModalBody>
              {duplicated}
              <ModalFooter className="d-block text-center">

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://blog.rayapp.io/es/google-my-business/perfiles-duplicados-gbp/"
                >
                <MDBBtn>{t("howToEliminateProfile")}</MDBBtn>
              </a>
                </ModalFooter>
                  </ModalBody>
            </Modal>
          </>
        )}
        {source.verified && (
          <>
            <FontAwesomeIcon
              data-tip
              data-for={"verified.info"}
              icon={faCheckCircle}
              color="#4b89ff"
              className="float-right"
            />
            <ReactTooltip
              id={"verified.info"}
              effect="solid"
              multiline
              className="w-25"
            >
              <span>{t("verifiedInfo")}</span>
            </ReactTooltip>
          </>
        )}
        <p className="text-truncate">{source.name}</p>
      </h3>
      <p className="text-dark">
        {source.address} <br/> {source.phone || t("noPhone")}
      </p>
      <small class="text-muted">{source.website || t("noWebSite")}</small>
      {source.name}
      <Score value={source.rating} />
      <small class="text-muted">
        {source.rating}/5 - {source.reviews + " " + t("reviews")} -{" "}
        {source.photos + " " + t("photos")}{" "}
      </small>
      <>
        <h3>{t("categories")}</h3>
        <p>
          {source.categories &&
            source.categories.map((category) => (
              <span className="badge badge-pill badge-info mr-2">
                {category}
              </span>
            ))}
          {(!source.categories || source.categories.length === 0) && (
            <span className="badge badge-pill badge-secondary mr-2">
              {t("noCategories")}
            </span>
          )}
          {source.socialLocationId && <ImprovementsModal socialLocationId={source.socialLocationId} t={t}/>}
        </p>
      </>
    </MDBCard>
  );
};

const calculateStatus = (improvements, currentCategories) => {
  if(!currentCategories || currentCategories.length === 0) return "text-danger";
  if (improvements[0] === currentCategories[0]) {
    const secondaryCategories = improvements.slice(1).sort();
    const currentSecondaryCategories = currentCategories.slice(1).sort();
    if(secondaryCategories.join() === currentSecondaryCategories.join())
      return "text-success";
  }
  return "text-warning";
}

export const ImprovementsModal = ({ socialLocationId, t, currentCategories }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [improvements, setImprovements] = React.useState("");
  const selectedCompany = useSelector((state) => state.application.selectedCompany);
  
  useEffect(() => {
    if(socialLocationId)
      getPlaceImprovementsSuggestions(selectedCompany.id, socialLocationId).then((res) => {
        setImprovements(res.data.data.categoriesImprovementOpportunity);
      });
  }, [socialLocationId]);

  const status= improvements && improvements.categories && calculateStatus(improvements.categories, currentCategories);

  if(!improvements) return null;
  
  return (
    <>
    <div>
      <div className="mb-1">{currentCategories && currentCategories.length} {t('assignedKeywords')}</div>
      {/* <FontAwesomeIcon 
        className={"mr-2 "+status }
        icon={status==="text-success" ? faCheckCircle :faExclamationTriangle}/>
      <span>{t("categoriesMessage."+status)}</span> */}
    { status!=="text-success" &&
      <span 
        className="mt-1"
        style={{padding: "3px 13px", borderRadius:'1.5rem', backgroundColor: 'unset', border: "solid 2px", color:"#2383E2"}} 
        onClick={() => setModalOpen(true)}>
          {t("seeImprovements")}
      </span>
    }
    </div>
    
    <Modal size="lg" isOpen={modalOpen} toggle={() => setModalOpen(false)}>
      <ModalHeader className="pb-0" toggle={() => setModalOpen(false)}><h2 className="mb-0">{t("improvements")}</h2></ModalHeader>
      <ModalBody style={{maxHeight: "60vh"}}>
        
        <p>{t("improvementsSubtitle")}</p>
        <div className="row" style={{margin:'0px'}}>
          <div className="col-12 col-md-6">
            <h3>{t("currentCategories")}</h3>
            <h4>{t("primary")}</h4>
            <div style={{display:"flex", justifyContent:'center', alignItems:'center', backgroundColor:'#f4f6f8', border:'2px solid #e4e8eb', borderRadius:'.3rem', padding:'5px', width:'fit-content'}}>
              <p style={{margin:'0'}}>{currentCategories[0]}</p>
            </div>
            <h4>{t("secondary")}</h4>
            {currentCategories.slice(1).map((category, index) => (
              <div style={{display:"flex", justifyContent:'center', alignItems:'center', backgroundColor:'#f4f6f8', border:'2px solid #e4e8eb', borderRadius:'.3rem', padding:'5px', width:'fit-content'}}>
                  <p style={{margin:'0'}}>{category}</p>
              </div>
              ))
            }
          </div>
          <div className="col-12 col-md-6" style={{backgroundColor:'rgb(235 251 255)', borderRadius:'1rem'}}>
            <h3>{t("recomendation")}</h3>
            <h4>{t("primary")}</h4>
            <div style={{display:"flex", justifyContent:'center', alignItems:'center', backgroundColor:'#f4f6f8', border:'2px solid #e4e8eb', borderRadius:'.3rem', padding:'5px', width:'fit-content'}}>
              <p style={{margin:'0'}}>#1 {improvements.categories[0]}</p>
            </div>
            <h4>{t("secondary")}</h4>
            {improvements.categories.slice(1).map((category, index) => (
              <div style={{display:"flex", justifyContent:'center', alignItems:'center', backgroundColor:'#f4f6f8', border:'2px solid #e4e8eb', borderRadius:'.3rem', padding:'5px', margin:'4px', width:'fit-content'}}>
                  <p style={{margin:'0'}}>#{index+1} {category}</p>
              </div> 
              ))
            }
          </div>
        </div>

        <h3>{t("improvementsfooter")}</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>{t("improvementsfooterSort")}</p>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div style={{width:"20px", height:'20px', backgroundColor:'#66fea2', marginRight:'5px'}}>
              </div>
              <p>{t("improvementsfooterAdd")}</p>
            </div>
          </div>
        </div>

      </ModalBody>
      <ModalFooter className="text-center">
        <a rel="noopener noreferrer" href={"https://www.youtube.com/@therayapp/videos"} target="_blank">
          <MDBBtn>{t("seeTutorial")}</MDBBtn>
        </a>
      </ModalFooter>
    </Modal>
    </>
  );
}

LocationCard.translations = {
  duplicated: {
    en: "Duplicated Profile",
    es: "Perfil Duplicado",
  },
  verifiedInfo: {
    en: "This profile is verified by Google",
    es: "Este perfil está verificado por Google",
  },
  noWebSite: {
    en: "No website",
    es: "Sin sitio web",
  },
  reviews: {
    en: "reviews",
    es: "reseñas",
  },
  photos: {
    en: "photos",
    es: "fotos",
  },
  categories: {
    en: "Categories",
    es: "Categorías",
  },
  howToEliminateProfile: {
    en: "How to eliminate this profile",
    es: "Cómo eliminar este perfil",
  },
  noCategories: {
    en: "No categories",
    es: "Sin categorías",
  },
  noPhone: {
    en: "No phone",
    es: "Sin teléfono",
  },
  improvements:{
    en: "Category improvements",
    es: "Análisis de categorías"
  },
  improvementsSubtitle:{
    en: "We analyze your keywords and based on the result, we recommend reordering them or adding them if they are missing",
    es: "Analizamos tus palabras clave y en base al resultado, te recomendamos reordenarlas o agregarlas en caso de que falten",
  }
};
export const LocationCardContainer = translate(LocationCard);
