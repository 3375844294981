import React from 'react';
import './loding.scss';
import { Image } from 'botbit-ui-components';
import { envConfig } from '../../../config/config';
import useWindowDimensions from '../../utils/useWindowDimensions';

const Loading = (props) => {
  const { isMedium } = useWindowDimensions();
  return (
    props.loading ? (
      <div
        onScroll={(e) => {
          e.stopPropagation();
        }}
        className={!props.fullScreen ? 'p-4 m-auto d-table' : 'loading-overlay'}
      >
        <div
          className={!props.fullScreen ? 'd-table m-auto' : 'loading-container'}
        >
          {/* <HashLoader
						sizeUnit={"px"}
						size={props.size? props.size : 100}
						color={!props.fullScreen? '#999' : '#fff'}
						loading={true}
					/> */}
          <Image
            style={{ width: props.small && isMedium ? '200px' : '400px', ...props.style }}
            src={`${envConfig.staticImgUrl}${props.white ? "loader_ray_blanco.gif" : "loader_ray.gif"}`}
            // src={`${envConfig.staticImgUrl}botbit-loader-blue.gif`}
            data-testid="loading-img"
          ></Image>
        </div>
      </div>
    ) : null
  );
};

export default Loading;
