import React from 'react';
import TimeAgo from 'react-timeago';
import { translate } from 'react-i18next';
import spanishStrings from 'react-timeago/lib/language-strings/es'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
 
const formatter = buildFormatter(spanishStrings);

class CustomTimeAgo extends React.Component {

	render(){
		if(!this.props.date){
			return (<span>{this.props.t(this.props.noDateText)}</span>)
		}
		return (<TimeAgo date={this.props.date} formatter={formatter}/>);
	}

}

export default translate('common')(CustomTimeAgo);
