import React, { useEffect, useState } from "react";
import {
  analyzePlaces,
  getPlacesByFlags as getPlaces,
  getSummary,
} from "../../../api/googleBusinessProfileApi";
import translate from "../../../config/translations/translate";
import { ImprovementsModal } from "./LocationCardContainer";
import "./styles.css";
import { useSelector } from "react-redux";
import { FirstConfigPageContainer } from "./FirstConfigPage";
import { LoadingPageContainer } from "./LoadingPage";
import { ErrorPageContainer } from "./ErrorPage";
import { Table, UxSelect, UxMetricDisplay } from "botbit-ui-components";
import "bootstrap/dist/js/bootstrap.bundle";
import { MDBBtn, MDBIcon } from "mdbreact";
import withEventTracking, { trackers } from "../../../config/withEventTracking";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import { setLoading } from "../../../commons/components/application/miscActions";
import TimeAgo from "../../../commons/components/utils/TimeAgo";
import SocialLocationPostContainer from "../SocialLocationPostContainer";

const buildCaption = (summary, t, openModal, openPostModal) => ({
  caption: {
    title: t("summary.captionTitle"), //"Mejora los siguientes indicadores para subir tu score!",
    elements: [
      {
        icon: "tag",
        value: summary.placesWithoutKeywords,
        description: t("summary.placesWithoutKeywords"), //"Locales sin Keywords",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
      },
      {
        icon: "sticky-note",
        value: summary.placesWithoutPosts,
        description: t("summary.placesWithoutPosts"), //"Locales sin publicaciones",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.makePost"),
          onclickHandler: openPostModal,
          type: "button",
        },
      },
      {
        icon: "camera",
        value: summary.placesWithoutPhotos,
        description: t("summary.placesWithoutPhotos"), //"Locales sin fotos",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
      },
      {
        icon: "phone-alt",
        value: summary.placesWithoutPhone,
        description: t("summary.placesWithoutPhone"), //"Locales sin teléfono",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
      },
      {
        icon: "globe",
        value: summary.placesWithoutWebsite,
        description: t("summary.placesWithoutWebsite"), //"Locales sin sitio web",
        highlighted: {
          text: "string",
          color: "danger",
        },
        callToAction: {
          text: t("summary.seeVideo"),
          onclickHandler: openModal,
          type: "link",
        },
      },
    ],
  },
});

const metricsConfiguration = (summary, t, openModal, openPostModal) => ({
  outstanding: {
    icon: "string",
    content: "React.Component",
    value: summary.score || 0,
    title: t("summary.currentScore"),
  },
  caption: buildCaption(summary, t, openModal, openPostModal).caption,
});

const BusinessProfilesTable = ({ t, trackEvent }) => {
  const selectedCompany = useSelector(
    (state) => state.application.selectedCompany
  );
  const [error, setError] = useState();
  const [loading, setloading] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [filters, setFilters] = useState({});
  const [summary, setSummary] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isPostModalOpen, setIsPostModalOpen] = useState();
  const [lastAnalysisDate, setLastAnalysisDate] = useState();

  const filtersConfig = [
    { id: "withoutKeywords", type: "checkbox", label: t("withoutKeywords") },
    { id: "withoutPosts", type: "checkbox", label: t("withoutPosts") },
    { id: "withoutPhotos", type: "checkbox", label: t("withoutPhotos") },
    { id: "withoutPhone", type: "checkbox", label: t("withoutPhone") },
    { id: "withoutWebsite", type: "checkbox", label: t("withoutWebsite") },
    {
      id: "all",
      type: "checkbox",
      label: t("all"),
      onClick: (f) => ({}),
      checked: (f) => Object.keys(f).filter((k) => k !== "query").length === 0,
    },
  ];

  const columns = [
    {
      dataField: "name",
      title: t("name"),
      headerClassName: "text-uppercase",
      size: 10,
    },
    {
      dataField: "phone",
      title: t("phone"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={source.phone || t("noPhone")}
            actionButton={{
              show: !source.phone,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
          />
        );
      },
      size: 10,
    },
    {
      dataField: "website",
      title: t("website"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={source.website || t("noWebSite")}
            actionButton={{}}
          />
        );
      },
      size: 10,
    },
    {
      title: t("address"),
      dataField: "address",
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={cellContent || t("noAddress")}
            actionButton={{
              show: !source.address,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
          />
        );
      },
      size: 20,
    },
    {
      dataField: "postHistoryCount",
      title: t("postHistoryCount"),
      headerClassName: "text-uppercase text-center",
      // className: "text-uppercase text-center d-inline",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={cellContent || t("noPosts")}
            actionButton={{
              show: !source.postHistoryCount,
              button: true,
              icon: { fas: true, icon: "plus" },
              color: "white",
              onclickHandler: () => setIsPostModalOpen(source.socialLocationId),
              buttonText: t("createPost"),
              className: "text-white",
            }}
          />
        );
      },
      size: 12,
    },
    {
      dataField: "photos",
      title: t("photos"),
      headerClassName: "text-uppercase text-center",
      className: "text-center",
      formatter: (cellContent, source) => {
        return (
          <CellFormatter
            content={cellContent || t("noPhoto")}
            actionButton={{
              show: !source.photos,
              icon: { far: true, icon: "play-circle" },
              // color: "white",
              onclickHandler: () => setIsOpen(true),
              buttonText: t("add"),
            }}
          />
        );
      },
      size: 12,
    },
    {
      title: t("categories"),
      className: "d-inline",
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        return (
          <ImprovementsModal
            socialLocationId={source.socialLocationId}
            currentCategories={source.categories || []}
            t={t}
          />
        );
      },
      size: 15,
    },
    {
      title: "",
      formatter: (cellContent, source) => {
        return (
          <a
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/place/?q=place_id:${source.placeId}`}
            target="_blank"
            className="m-auto text-dark"
          >
            <MDBIcon fas icon="external-link-alt" />
            <span className="pl-2"> {t("seePlace")}</span>
          </a>
        );
      },
      size: 11,
    },
  ];

  const emmit = (action) =>
    trackEvent(action.toLowerCase(), {}, false, [
      trackers.BACKEND,
      trackers.MIXPANEL,
    ]);

  React.useEffect(() => {
    if (loading || firstTime) return;
    emmit("GBP_audit_viewed");
    getSummary(selectedCompany.id).then((res) => {
      if (res.data.data) {
        setSummary(res.data.data);
      }
    });
  }, [loading, firstTime]);

  const analyze = () => {
    setFirstTime(false);
    setloading(true);
    analyzePlaces(selectedCompany.id, (e) => {
      setLoading(false);
      setError(e);
    })
      .then(() => setloading(false))
      .catch((e) => setError(e));
  };

  if (error) {
    return <ErrorPageContainer error={error} />;
  } else if (firstTime) {
    return <FirstConfigPageContainer analyze={analyze} />;
  } else if (loading) {
    return <LoadingPageContainer />;
  } else {
    const getTableData = (page, order, filters) =>
      getPlaces(page, order, {
        companyId: selectedCompany.id,
        ...filters,
      }).then((res) => {
        if (res.data.data) {
          if (res.data.data.analysisInProgress) setLoading(true);
          if (!res.data.data.lastAnalysisDate) setFirstTime(true);
          else setLastAnalysisDate(res.data.data.lastAnalysisDate);
        }

        return res;
      });
    return (
      <div className="gbp-container">
        <h1>{t("title")}</h1>
        <div className="row">
          <div className="col-12 col-md-9">
            <p>{t("subtitle")}</p>
          </div>
          <div className="col-12 col-md-3">
            <span>
              {t("analyze")}
              <TimeAgo date={lastAnalysisDate}></TimeAgo>
            </span>
            <MDBBtn
              size="sm"
              onClick={() => analyze()}
              style={{ width: "50px", padding: "10px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#ffffff"
                  d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"
                />
              </svg>
            </MDBBtn>
          </div>
        </div>
        {summary && (
          <>
            <UxMetricDisplay
              data={metricsConfiguration(summary, t, () => setIsOpen(true), ()=>setIsPostModalOpen(-1))}
            />
            <VideoModal
              description={t("summary.videoDescription")}
              title={t("summary.videoTitle")}
              subtitle={t("summary.videoSubtitle")}
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              url={"https://www.youtube.com/watch?v=Qx8ElTKfE-g"}
            />
            <PostModal
              isOpen={isPostModalOpen !== undefined}
              toggle={() => setIsPostModalOpen(undefined)}
              title={t("createPost")}
              socialLocationId={isPostModalOpen}
            />
          </>
        )}
        <div className="my-5">
          <Filters
            onFilterChange={setFilters}
            filters={filtersConfig}
            queryInputLabel={t("searchLabel")}
          />
        </div>
        <Table
          pagination={false}
          getData={getTableData}
          filterValues={filters}
          title={t("title")}
          subTitle={<p>{t("subtitle")}</p>}
          columns={columns}
          showMobileColumnTitles={false}
        />
      </div>
    );
  }
};

const Filters = ({
  onFilterChange,
  filters,
  showQueryString = true,
  queryInputLabel,
}) => {
  const [filtersState, setFiltersState] = useState({});

  useEffect(() => {
    onFilterChange(filtersState);
  }, [filtersState]);

  return (
    <div className="row justify-content-start ml-1">
      {filters.map((filter) => {
        switch (filter.type) {
          case "select":
            return (
              <div className="col-12 col-md-3">
                <UxSelect
                  options={filter.options}
                  label={filter.label}
                  onChange={(a) => {
                    const newFilters = { ...filtersState };
                    newFilters[filter.id] = a;
                    setFiltersState(newFilters);
                  }}
                ></UxSelect>
              </div>
            );
          case "checkbox":
            return (
              <div className="col-12 col-md-1 px-0">
                <span>
                  <input
                    type="checkbox"
                    checked={
                      filter.checked !== undefined
                        ? filter.checked(filtersState)
                        : filtersState[filter.id]
                    }
                    className="m-1"
                    style={{
                      position: "unset",
                      pointerEvents: "unset",
                      opacity: 1,
                    }}
                    onChange={(e) => {
                      if (filter.onClick) {
                        setFiltersState(filter.onClick(filtersState) || {});
                        return;
                      }
                      const newFilters = { ...filtersState };
                      newFilters[filter.id] = e.target.checked;
                      setFiltersState(newFilters);
                    }}
                  />
                  <span className="align-top">{filter.label}</span>
                </span>
              </div>
            );
          default:
            return null;
        }
      })}
      {showQueryString && (
        <div
          class="form-outline col-12 col-md-3 offset-md-3"
          data-mdb-input-init
        >
          <input
            type="text"
            id="formControlLg"
            class="form-control form-control-lg h-100"
            onChange={(e) => {
              const newFilters = { ...filtersState };
              newFilters.query = e.target.value;
              setFiltersState(newFilters);
            }}
            placeholder={queryInputLabel || "Search"}
          />
        </div>
      )}
    </div>
  );
};

BusinessProfilesTable.translations = {
  title: {
    en: "Google Business Profiles",
    es: "Perfiles de Google My Business",
  },
  subtitle: {
    en: "Welcome to our new Google Business Profiles report!",
    es: "¡Bienvenido a nuestro nuevo reporte de Perfiles de Google My Business!",
  },
  searchLabel: {
    en: "Search",
    es: "Buscar por nombre, provincia, país",
  },
  searchPlaceholder: {
    en: "Search location",
    es: "Buscar ubicación",
  },
  verifiedInfo: {
    en: "This profile is verified by Google",
    es: "Este perfil está verificado por Google",
  },
  noWebSite: {
    en: "No website",
    es: "Sin sitio web",
  },
  reviews: {
    en: "Rate",
    es: "Rate",
  },
  photos: {
    en: "photos",
    es: "fotos",
  },
  categories: {
    en: "Categories",
    es: "Categorías",
  },
  howToEliminateProfile: {
    en: "How to eliminate this profile",
    es: "Cómo eliminar este perfil",
  },
  noCategories: {
    en: "No categories",
    es: "Sin categorías",
  },
  noPhone: {
    en: "No phone",
    es: "Sin teléfono",
  },
  name: {
    en: "Title",
    es: "Título",
  },
  address: {
    en: "Address",
    es: "Dirección",
  },
  complete: {
    en: "Complete",
    es: "Completa",
  },
  noPhoto: {
    en: "No photo!",
    es: "¡No tienes fotos!",
  },
  duplicated: {
    en: "Duplicated",
    es: "Duplicado",
  },
  profileSelectLabel: {
    en: "Profiles",
    es: "Perfiles",
  },
  descriptionStatusLabel: {
    en: "Description",
    es: "Descripción",
  },
  incomplete: {
    en: "Incomplete",
    es: "Incompleta",
  },
  toExtensive: {
    en: "To extensive",
    es: "Muy larga",
  },
  none: {
    en: "None",
    es: "No tiene",
  },
  categoriesStatusLabel: {
    en: "Categories",
    es: "Categorías",
  },
  improvements: {
    en: "Category improvements",
    es: "Análisis de categorías",
  },
  improvementsSubtitle: {
    en: "Based on the queries your customers perform to find your location, we recommend changing the categories of your Google Business Profile as follows:",
    es: "Según las consultas que realizan sus clientes para encontrar su ubicación, le recomendamos cambiar las categorías de su perfil comercial de Google de la siguiente manera",
  },
  currentCategories: {
    en: "Current",
    es: "Actuales",
  },
  recomendation: {
    en: "Recommendation",
    es: "Recomendadas",
  },
  seeTutorial: {
    en: "See tutorial",
    es: "Ver tutorial",
  },
  categoriesMessage: {
    "text-danger": {
      es: "Necesitas agregar palabras clave!",
      en: "You need to add keywords!",
    },
    "text-warning": {
      es: "Tus categorias pueden ser optimizadas",
      en: "Categories can be optimized",
    },
    "text-success": {
      es: "Tus palabras clave son correctas",
      en: "Your keywords are correct",
    },
  },
  seeImprovements: {
    es: "Ver sugerencias",
    en: "+ See improvements",
  },
  assignedKeywords: {
    en: "Assigned Keywords",
    es: "Categorías asignadas",
  },
  seePlace: {
    es: "Ver página",
    en: "See place",
  },
  addAddress: {
    es: "Agrega una dirección",
    en: "Add an address",
  },
  primary: {
    es: "Primaria",
    en: "Primary",
  },
  secondary: {
    es: "Secundarias",
    en: "Secondary",
  },
  improvementsfooter: {
    en: "To improve",
    es: "Para mejorar",
  },
  improvementsfooterSort: {
    en: "# . update the order",
    es: "# . actualiza el orden",
  },
  improvementsfooterAdd: {
    en: "Add new categories",
    es: "Agregar nuevas categorias",
  },
  analyze: {
    en: "Last update  ",
    es: "Actualizados ",
  },
  directionStatus: {
    INCOMPLETE: {
      en: "Incomplete address",
      es: "Dirección Incompleta",
    },
    MATCHED: {
      en: "Correct address",
      es: "Dirección correcta",
    },
    MISMATCHED: {
      en: "Mismatched direction",
      es: "Dirección no coincidente",
    },
  },
  summary: {
    captionTitle: {
      en: "Improve the following indicators to increase your score!",
      es: "Mejora los siguientes indicadores para subir tu score!",
    },
    placesWithoutKeywords: {
      en: "Places without Keywords",
      es: "Locales sin Keywords",
    },
    placesWithoutPosts: {
      en: "Places without posts",
      es: "Locales sin publicaciones",
    },
    placesWithoutPhotos: {
      en: "Places without photos",
      es: "Locales sin fotos",
    },
    placesWithoutPhone: {
      en: "Places without phone",
      es: "Locales sin teléfono",
    },
    placesWithoutWebsite: {
      en: "Places without website",
      es: "Locales sin sitio web",
    },
    currentScore: {
      en: "Your current score is:",
      es: "Tu score actual es:",
    },
    seeVideo: {
      en: "Ver video",
      es: "Ver video",
    },
    videoTitle: {
      en: "¿Cómo mejorar tu perfil de Google My Business?",
      es: "¿Cómo mejorar tu perfil de Google My Business?",
    },
    videoSubtitle: {
      en: "Descubre cómo mejorar tu perfil de Google My Business",
      es: "Descubre cómo mejorar tu perfil de Google My Business",
    },
    videoDescription: {
      en: "En este video te mostramos cómo mejorar tu perfil de Google My Business",
      es: "En este video te mostramos cómo mejorar tu perfil de Google My Business",
    },
    makePost: {
      en: "+ Post",
      es: "+ Postear",
    },
  },
  postHistoryCount: {
    en: "Posts",
    es: "Posts",
  },
  phone: {
    en: "Phone",
    es: "Teléfono",
  },
  withoutKeywords: { es: "Categorías", en: "Keywords" },
  withoutPosts: { es: "Posts", en: "Posts" },
  withoutPhotos: { es: "Fotos", en: "Photos" },
  withoutPhone: { es: "Teléfono", en: "Phone" },
  withoutWebsite: { es: "Website ", en: "Website" },
  all: { es: "Ver Todos ", en: "All" },
  add: { es: "Agregar", en: "Add" },
  createPost: { es: "Crear post", en: "Create post" },
  noPosts: { es: "¡No tienes posts!", en: "No posts!" },
};

const VideoModal = ({ isOpen, toggle, title, subtitle, description, url }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Qx8ElTKfE-g?si=nfr9e32wx-bb4LDY&amp;start=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <h4>{subtitle}</h4>
        <p>{description}</p>
      </ModalBody>
    </Modal>
  );
};

const PostModal = ({ isOpen, toggle, socialLocationId }) => {
  return (
    <SocialLocationPostContainer
      modal={{
        isOpen,
        toggle,
      }}
      socialLocationId={socialLocationId}
    />
  );
};

const CellFormatter = ({ content, actionButton }) => {
  return (
    <div className="d-inline-block  text-truncate">
      <div>{content}</div>
      {actionButton.show && (
        <div>
          <div
            className={
              actionButton.button
                ? "metric-button text-white "
                : "metric-call-to-action"
            }
            onClick={actionButton.onclickHandler}
          >
            <>
              <MDBIcon
                style={{ color: actionButton.color || "gray" }}
                fixed
                iconType={actionButton.iconType || "solid"}
                size="1x"
                className={actionButton.className || " text-dark"}
                {...actionButton.icon}
              />
              <div className={actionButton.button ? "text-white" : "text-dark"}>
                {actionButton.buttonText}
              </div>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export const BusinessProfilesTableContainer = withEventTracking(
  translate(BusinessProfilesTable)
);
